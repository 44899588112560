import React from "react";
//import Contactcard from "./ContactCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactForm = () => {
  return (
    <div className="ctr container neu-div card bg-dark p-5 mb-3">
      <form
        action="https://formspree.io/f/xdkologp"
        method="POST"
        className="text-start"
      >
        <div className="form-group ">
          <label className="lbl  fs-5" htmlFor="name">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="username"
            className="form-control mb-3 neu-input"
            placeholder="Enter your name"
            required
          />
        </div>
        <div className="form-group">
          <label className="lbl fs-5" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control mb-3 neu-input"
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="form-group">
          <label className="lbl fs-5" htmlFor="message">
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            className="form-control mb-3 neu-input"
            placeholder="Enter your message"
            rows="4"
            required
          />
        </div>
        <button type="submit" className="btn btn-secondary neu-input mt-3">
          Send Message
        </button>

        {/* <Button type='submit' variant="contained" className="butn mx-auto" endIcon={<Send />}>
  Send Message
</Button> */}
      </form>
    </div>
  );
};

const Contact = () => {
  return (
    <footer className="container pt-3 my-5 " id="contact">
      <h1 className="p-5 text-center">Contact Me</h1>
      <div className="row">
        <div className="col-md-6 col-12">
          <ContactForm />
        </div>
        <div className="col-md-6 col-12">
          <div className="d-flex  flex-column justify-content-center align-items-center h-100">
            <h4 className="mb-5">OR Connect With Me On</h4>
            <p onClick={() => window.open("mailto:mayanksahu18.me@gmail.com")}>
              <a
                href="mailto:mayanksahu18.me@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={`fa-solid fa-envelope`}
                  className="rounded-circle border border-info p-2 border-3 fs-5 neu-button align-baseline"
                />
              </a>
              <span className="fs-5 align-top ps-2">
                mayanksahu18.me@gmail.com
              </span>
            </p>
            <p onClick={() => window.open("tel:+919307531964")}>
              <a
                href="tel:+919307531964"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={`fa-solid fa-phone-alt`}
                  className="rounded-circle border border-info p-2 border-3 fs-5 neu-button align-baseline"
                />
              </a>

              <span className="fs-5 align-top ps-2">
                {" "}
                (+91) 9307531964 / 9329859030{" "}
              </span>
            </p>
            <p>
              <a
                href="https://www.linkedin.com/in/mayanksahu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={`fa-brands fa-linkedin`}
                  className="rounded-circle border border-info p-2 m-2 border-3 fs-4 neu-button align-baseline"
                />
              </a>
              <a
                href="https://github.com/Mayank8085"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={`fa-brands fa-github`}
                  className="rounded-circle border border-info p-2  m-2 border-3 fs-4 neu-button align-baseline"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Contact;
//{
/* <div className='row my-5' >
               <Contactcard/>
               <Contactcard
               title='Email'
               icon='fa-envelope'
               main1='mayanksahu18.me@gmail.com'
               main2='mayanksahu1806@gmail.com'
               />
               <Contactcard
               title='Phone'
               icon='fa-phone-alt'
               main1='+91 930-753-1964'
               main2='+91 932-984-9030'
               />
           </div> */
//}
